<template>
  <div class="text-xs bg-gray-800 tabs sub-tabs">
    <template v-for="tab in tabs">
      <a
        href="#"
        class="border-b-2 tab"
        :class="{
          'font-bold border-gray-700 rounded-t-sm': active === tab.type
        }"
        @click.prevent="active = tab.type"
      >
        <span class="text-gray-100">{{ tab.label }}</span>
      </a>
    </template>
      <WorldPanel
        v-if="active === 'world'"
        :gui="viewer.gui"
        @settings-changed="$emit('settings-changed', $event)"
      />
      <TimelinePanel 
        v-if="active === 'timeline'"
        :gui="viewer.gui"
        :sequence="viewer.sequence"
        @settings-changed="$emit('settings-changed', $event)"/>
      <RenderPanel 
        v-if="active === 'render'"
        :gui="viewer.gui"
        @settings-changed="$emit('settings-changed', $event)"/>
  </div>
</template>
<script>
const WorldPanel = () =>
  import('@components/editor/scene/viewer/panels/WorldPanel')
const TimelinePanel = () =>
  import('@components/editor/scene/viewer/panels/TimelinePanel')
const RenderPanel = () =>
  import('@components/editor/scene/viewer/panels/RenderPanel')

export default {
  name: 'SettingsTab',
  props: {
    viewer: {
      type: Object,
      required: true
    }
  },
  components: {
    WorldPanel,
    TimelinePanel,
    RenderPanel
  },
  data() {
    return {
      tabs: [
        { type: 'world', label: 'World' },
        { type: 'timeline', label: 'Timeline' }
      ],
      active: 'world'
    }
  }
}
</script>
